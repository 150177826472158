:root
{
  --FontXXS: 12px;
  --FontXS: 14px;
  --FontS: 16px;
  --FontS1: 20px;
  --FontS2: 22px;
  --FontM: 26px;
  --FontL: 32px;
  --FontLL: 42px;
  --FontXL: 48px;
  --FontXXL: 54px;
  --FontXXXL: 80px;
  --FontWeightLight: 100;
  --FontWeightMedium: 400;
  --FontWeightHeavy: 600;
  --FontWeightVeryHeavy: 900;
}

:root
{
    --dolphin: #767676;
    --newDolphin: #848689;
    --elephant: #ececec;
    --seal: #989898;
    --Tangerine: #F56200;
    --Tawny: #C95000;
    --BernRed: #e20909;
    --Cantaloupe: #FEDCC4;
    --DarkCantaloupe: #faad76;
    --Cantalight: #FFF4EC;
    --shark: #4A4A4A;
    --Raven: #222222;
    --RavenDark:  #222222;
    --StormGreen:#113333;
    --Lacustral: #19504c;
    --snapper: #CDCDCD;
    --wolf: #070606;
    --thunderfox: #D8D8D8;
    --silverfox: #F1F1F1;
    --swan: #FFFFFF;
    --Indigo2: #187F89;
    --Indigo1: #6E86F0;
    --Indigo: #0196C2;
    --TTFMPurplev1: #9A9CCC;
    --TTFMPurple2: #46B1C9;
    --TTFMPurple: #C0E8F2;
    --WaterySea: #88bfe7;
    --TTFMRed1: #F29B93;
    --TTFMRed2: #FCAA70;
    --TTFMRed: #FF781E;
    --SassySalmon: #ee7c54;
    --FireEngineRed: #BE291B;
    --CoralRed: #D27360;
    --CoralRedDark: #86181d;
    --Firebrick: #B4282E;
    --CoralArtery: #581115;
    --Butter: #EDDA7D;
    --BananaClan: #F5DB00;
    --GreenApple: #00C681;
    --GreenAppleDark:  #4e643a;
    --Crocodile: #007C5C;
    --BoldGreen: #00b53f;
    --Bluejay: #0196C2;
    --Bluejay1: #4A90E2;
    --BluejayDark: #004d64;
    --Ocean: #0196C2;
    --Oceanlight: #C0E8F2;
    --ActiveDodgerBlue: #2A6BFF;
}


html, body {
  margin: 0;
  padding: 0;
  font-family: 'Segoe UI', Helvetica, Arial, Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 300;
}

body {
  max-height: calc(100vh);
  overflow-y: scroll !important;
  overflow-x: scroll !important;
}

body::-webkit-scrollbar { width: 12px;}

body::-webkit-scrollbar-track { background: #f1f1f1;  }

body::-webkit-scrollbar-thumb { background: var(--seal);  }

body::-webkit-scrollbar-thumb:hover { background: var(--dolphin);  }

#root {
  
}
.App {
  text-align: center;
}
p, h4, h5, h6 {
  margin: 0.5rem 0;
}
.top-holder {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
}

.shown-pane {
  left: 0px;
}


.mainContainer
{
  margin-top: 5rem;
}





.superHeader {
  height: 5rem;
  background-color: #eaeaea;
  display: flex;
  box-shadow: none;
  position: fixed;
  top: 0;
  width: calc(100vw);
  z-index: 999;
  border-bottom: 1px solid #999;
  max-width: calc(100vw);
}
.dashboardLogo {
  margin-left: 0;
  margin-right: 0;
  display: flex;
  background-color: var(--Tangerine);
}
.dashboardLogo img,
.dashboardLogo svg {
  height: auto;
  margin-left: 0.25rem;
  min-width: 1.75rem;
  width: 1vw;
}
.superName {
  background-color: var(--Tangerine);
  color: #fff;
  font-size: 1.5rem;
  margin-left: 0;
  margin-right: 0.25rem;
  text-align: left;
  font-weight: 600;
  padding: 1rem 0.5rem 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-self: stretch;
  width: calc(6vw);
  min-width: 6rem;
}
.content {
  display: flex;
  margin: 12rem 10% 0 10%;
}
.bigButton {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  border: none;
  box-shadow: 2px 3px 8px rgba(0,0,0,0.2);
  padding: 3rem 4rem;
  cursor: pointer;
  flex: 1;
}
.bigButton.leftButton {
  margin-right: 5%;
}
.bigButton.rightButton {
  margin-left: 5%;
}
.logoHere {
  min-height: 8rem;
  height: 8rem;
}
.textHere {
  font-size: 1.25rem;
  font-weight: 600;
  color: #777;
}


.superName .dropdown {
  background-color: #F56200;
}
.dropdownArrow {
  padding-left: 0.5rem;
  align-self: center;
}

.medLogo img,
.medLogo svg {
  height: auto;
  width: 100%;
}
.medMiniText {
  line-height: 1rem;
  font-size: 1rem;
  margin-top: 0;
}
.medMiniText .beta {
  font-size: 1rem;
  font-weight: 400;
}
.superMiddle {
  display: flex;
  justify-content: left;
}
.TopTile {
  display: flex;
  flex-direction: column;
  margin-right: 0.25rem;
  min-width: 3rem;
}

.TopTileWrapp {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  padding: 0 0.25rem;
  background: #B8DC8B;
  color: #333;
}

.full-tile
{
  color: #333;
  font-size: 1.5rem;
  flex: 2 1;
  line-height: 3rem;
  min-height: 3rem;
  font-weight: normal;
  align-items: stretch;
  min-height: 77px;
}

.tiles-container-compare .TopTileWrapp 
{
  background: rgba(0,0,0,0.2);
  position: relative;
}

.top-script
{
  color: grey;
  position: absolute;
  right: 0.1rem;
}

.TopTileWrapp .large-value {
  font-size: 1.5rem;
  flex: 2;
  line-height: 3rem;
  min-height: 3rem;
  font-weight: normal;
}

.bigTile .TopTileWrapp .large-value {
  flex: 1 1;
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin-top: .5rem;
  min-height: 1.75rem;
  font-weight: 600;
}

.TopTileWrapp .small-label {
  font-size: 1rem;
  padding: 0 1rem 0.5rem;
  

}
.verticalText {
  display: flex;
  flex-direction: column;
  align-self: center;
  min-width: 6rem;
}

.superHeader .bigTile .dropdown {
  margin: 0 0 0.25rem 0;
  flex: 2;
}

.superHeader .dropdown .small-label {
  padding: 0;
  font-size: 1.125rem;
  font-weight: 600;
}


.systemsTile {
  flex-direction: row;
}
.systemsTile .TopTileWrapp {
  padding: 0 0.75rem;
  background-color: #C6E3AA;
}
.systemsTile .large-value {
  font-size: 2.25rem;
  max-height: 3.25rem;
  line-height: 2.5rem;
  min-height: 2.75rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.systemsTile .small-label {
  font-size: 1rem;
  padding: 0;
  line-height: 1.25rem;
  font-weight: 600;
}

.systemsTile .TopTileWrapp:first-child {
  min-width: 4rem;
}
.systemsTile .TopTileWrapp:first-child .large-value {
  font-size: 2.25rem;
  max-height: 3.25rem;
}

.system-tile-wrap .large-value
{
  font-size: 1.5rem;
  max-height: 1.3rem;
  min-height: 1.3em;
  overflow: hidden;
}

.system-tile-wrap .medium-value
{
  font-size: 1.3rem;
  max-height: 1.3rem;
  min-height: 1.3em;
  overflow: hidden;
}

.system-tile-wrap  .small-label {
  font-size: 1rem;
  padding: 0 1rem 0.5rem;
}

.system-tile-wrap
{
  display: flex;
  flex: 1 1;
  flex-direction: column;
  padding: 0 1rem;

  color: #333;
}

.data .TopTileWrapp {
  background: #EDDA7C;
}

.TopTile.grid2x2 {
  display: flex;
  flex-direction: row;
  background-color: #EDDA7C;
  padding: 0 1rem;
  text-align: left;
}
.TopTileLeftCol, .TopTileRightCol {
  display: flex;
  flex-direction: column;
  margin: 0;
  border-right: 1px solid #ccc;
  padding: 0 .725rem;
  width: 100%;
  min-width: max-content;
}
.MainProgressbar {
  min-width: 9rem;
  padding-left: 0.75rem;
  padding-top: 0.25rem;
  max-width: 14rem;
}
.TopTileLeftCol .small-label, .TopTileRightCol .large-value {
  flex: 1;
  vertical-align: middle;
  line-height: 2.5rem;
}
.TopTileLeftCol .small-label {
  padding-right: 1rem;
  text-align: right;
}
.TopTileRightCol .large-value {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 20rem;
}

.superLogo {
  align-self: center;
  margin-right: 1rem;
}
.superLogo img {
  height: 2rem;
}

.superEnd {
  display: flex;
  align-self: stretch;
  margin-left: auto;
}

.feedback-button
{
  min-width: 6rem;
}
.superEnd.togglingButtons {
  align-items: center;
  flex: 0;
  flex-basis: 32rem;
}


.feedback-button.selected
{
  background-color: #EE803A;
}


.superHeader .progressbarTitle {
  margin-top: 0.5rem;
}



/* RECHARTS */
.recharts-layer [type="category"] {
  font-size: 1rem;
}
.chart-title {
  font-weight: 600;
  margin-bottom: 0.5rem;
  background: #eee;
  padding: 0.5rem 0;
  color: #000;
}

.chart-filter
{
  display: inline-block;
 
}

.chart-filter .dropdown
{
  margin-left: 40px;
  
  border-style: solid;
  border-color: #333;
  border-width: 1px;
  min-width: 90px;
}

.chart-filter .dropdown-label
{
  display: flex;
}


.chart-filter .dropdown-content
{
  top: 2.8em;
  left: -0.1em;
  min-width: 50px;
}
.chart-filter .dropdown-item
{
  cursor: pointer;
  padding-top: 5px;
}

.TopLongContainer .pane1 .recharts-layer text {
  color: transparent;
  fill: #000;
  stroke: none;
  letter-spacing: 1px;
}

.container3 .recharts-layer text {
  z-index: 999;
}

.container2 .recharts-layer text {
  font-size: 0.825rem;
}



.TopLongContainer {
  margin-bottom: 1rem;
  position: relative;
}

.compare-container {
  margin-bottom: 3rem;
}

.BottomRowContainer {
  display: flex;
  flex: 1;
  padding-top: 0rem;
  margin-top: 0rem;
}

.systemUsage
{
  margin-bottom: 2rem;
}

.maindashboard .BottomRowContainer {
  margin-top: 0rem;
  padding-top: 0rem;
}

.BottomRowContainer .container {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
}
.BottomRowContainer .container:first-child {
  margin-left: 0;
}

.recharts-responsive-container > div {
  width: 100%;
}

.carousel-container
{
  position: relative;
  overflow:hidden;
  height: 200vh;
  width: 100%;
}

.carousel-slide
{
  position: absolute;
  left: 100%;
  -webkit-transition: left 1s ease-in;
  transition: left 1s ease-in;
  height: 100vh;
    width: 100%;
}


.sub-carousel-container
{
  position: relative;
  overflow:hidden;
  height: 480px;
  width: 100%;
}

.mini-sub-carousel-container
{
  position: relative;
  overflow:hidden;
  height: 480px;
  width: 640px;
}

.sub-carousel-slide
{
  position: absolute;
  left: 100%;
  -webkit-transition: left 1s ease-in;
  transition: left 1s ease-in;
  height: 480px;
    width: 100%;
}

.pane
{
  position: absolute;
  -webkit-transition: left 1s ease-in;
  transition: left 1s ease-in;
  height: 480px;
    width: 100%;
}

.shown-pane
{
  left: 0px;
}

.like-button
{
  max-width: 50px;
  max-height: 50px;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 365px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1;
  top: 2rem;
}

.dropdown-content-scroll
{
  max-height: 360px;
  overflow-y: scroll;
  padding-left: 1rem;
  padding-right: 0.5rem;
  box-shadow: -8px 0 4px rgba(0,0,0,0.1) inset;
}
.dropdown-content-scroll .dropdown-item:last-child
{
  margin-bottom: 1rem;
}

.dropdown-content-scroll.footer
{
  max-height: 280px;
  min-height: 6rem;
}

.dropdown-opener
{
  cursor: pointer;
  font-size: 1rem;
  display: flex;
}

.superHeader .dropdown-opener
{
  height: 100%;
  padding: 0 0.5rem;
}

.searchfield {
  margin-bottom: 0.5rem;
}

.text-input
{
  min-width: 36rem;
  padding: 0.25rem;
  min-height: 2rem;
  max-height: 2rem;
  flex: 1;
  display: inline-flex;
  font-size: large;
  border-color: #AAA;
  border-width: 1px;
}
input.text-input::placeholder,
input.text-input::-webkit-input-placeholder,
input.text-input:-ms-input-placeholder,
input.text-input::-ms-input-placeholder
{ 
  color: #888!important;
  opacity: 1;
  font-style: italic;                      
}
input.text-input::placeholder {
  color: #999;
  opacity: 1;
  font-style: italic; 
}

.footer .text-input
{
  min-width: auto;
  margin-right: 0.5rem;
}

.item-group {
  display: flex;
  padding-top: 1rem;
}

.dropdown {
  position: relative;
  justify-content: center;
  align-self: stretch;
  padding: 0;
  background-color: #F56200;
}

.dropdown-content.open {
  display: block;
  padding: 1rem 0 0 0;
  margin-left: -0.75rem;
  left: 0.5rem;
}
.dropdown-content.open .footer {
  background-color: #eee;
}

.dropdown-item.footer .main-label {
  display: inline-flex;
  padding-top: 0.5rem;
}

.dropdown-item
{
  color: #000;
  font-size: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.dropdown-content-scroll .dropdown-item
{
  display: flex;
  flex-direction: row;
  padding: 0;
  font-size: 1.25rem;
  align-items: center;
  margin: 0.25rem 0;
}

.dropdown-content-scroll .dropdown-item .main-label,
.dropdown-content-scroll .dropdown-item .sub-label,
.dropdown-content-scroll .dropdown-item input[type='checkbox']
{
  padding-bottom: 0.25rem;
}

.dropdown-content-scroll .dropdown-item:hover
{
  background-color: #ddd;
  outline: 1px solid #eee;
}

.dropdown-item.tableTh {
  display: flex;
  flex-direction: row;
}

.dropdown-item.heading .main-label
{
  font-weight: 200;
  margin-left: 2rem;
  font-size: 1rem;
  padding-top: 0.75rem;
}
.dropdown-item.heading .sub-label
{
  font-weight: 200;
  margin-left: 2rem;
  padding-top: 0.75rem;
}

.dropdown-item .main-label
{
  font-weight: 400;
  margin-left: 0.25rem;
}

.dropdown-item .sub-label
{
  font-weight: 200;
  margin-left: 5rem;
}

.dropdown-item input
{
  accent-color: #EE803A;
}

input[type='checkbox']:checked {
  background-color: #EE803A;
}
input[type='checkbox']:checked:after {
  content: '\2713';
  color:white;
}

input[type='checkbox']{
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  width: 1.25rem !important;
  height: 1.25rem !important;
  appearance:none;
  border-radius:10%;
  border: 1px solid rgb(191 35 42 / 90%);
  box-shadow: none;
  font-size: 1em;
  margin: 0 0.25rem 0 0;
}



.serials-label
{
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 4rem;
  font-size: 1.25rem;
  max-width: 18rem;
  align-self: center;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.slidecontainer
{
  width: 600px;
  min-height: 4rem;
  position: relative;
}

.slider-label
{
  color: #333;
  margin-top: 15px;
}

.small-dropdown-label
{
  display: inline-block;
}

.dropdown-content-scroll.footer .dropdown-item .main-label
{
  max-width: 7rem;
  min-width: 7rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dropdown-content-scroll.footer .dropdown-item .sub-label
{
  margin-left: 1rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 24rem;
}

.small-dropdwon
{
  border-color: #AAA;
  border-width: 1px;
  border-style: solid;
min-width:  260px;
  display: inline-block;
  margin: 10px;
}


.small-dropdwon .dropdown-opener
{
  float: right;
  margin-right: 15px;
}

.small-dropdwon-text
{
  display: inline-block;
}

.dropdown-content.small
{
  position: absolute;
  background-color: #f9f9f9;
  min-width: 235px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1;
 top: 45px;
}

.dropdown-item-plain
{
  cursor: pointer;
  font-weight: 600;
}

.dropdown-item-plain-sub
{
 font-style: italic;
 font-weight: 400;
 color: #AAA;
 margin-left: 5px;
}

.systematicUsage {
  position: relative;
  margin-bottom: 8rem;
}
.tiles-container
{
  display: flex;
  align-items: flex-end;
  margin: 0.25rem 0 -4.5rem 0;
  position: absolute;
  right: 0;
  bottom: 0;
  
}

.tiles-container-compare
{
  display: flex;
  align-items: flex-start;
  flex-direction: column;

}



.sub-button
{
  background-color: #AAA;
  border: none;
  color: #FFF;
  text-align: center;
  margin-left: auto;
  padding: 0.75rem 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  align-self: stretch;
  font-size: 1rem;
}
.sub-button.iconButton
{
  min-width: 3rem;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
}
.sub-button.iconButton img
{
  width: 1.125rem;
}


.dropdown-content.open .BottomButtons {
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #ddd;
  border-top: 1px solid #ccc;
  justify-content: center;
}

.button-main.enabled {
  color: #FFF;
  background-color: #EE803A;
  border-color: #EE803A;
  pointer-events: visible;
  cursor: pointer;
  font-weight: bold;
}

.button-main {
  background-color: #777;
  border: none;
  color: #FFF;
  text-align: center;
  border-radius: 0.5rem;
  flex-basis: 8rem;
  cursor: pointer;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 3rem;
  min-width: 5rem;
}
.button-main:hover {
  background-color: #333;
}
.togglingButtons {
  display: flex;
  flex: 2;
  margin-right: 1rem;
}
.togglingButtons button {
  flex: 1;
  border-radius: 0;
}
.togglingButtons button:first-child {
  border-radius: 0.5rem 0 0 0.5rem;
}
.togglingButtons button:last-child {
  border-radius: 0 0.5rem 0.5rem 0;
}

.BottomButtons button:first-child {
  margin-left: 0;
}
.BottomButtons button:last-child {
  margin-right: 0;
}

.feedback-holder
{
 min-height: 200px;
}

.feedback-holder .recharts-label-list .authorName {
  max-width: 6rem!important;
  overflow: hidden!important;
  border: 1px solid red;
  display: inline-flex;
}

.idea-box
{
  min-height: 100px;
  width: 80%;
  margin: 30px;
  font-size: 2em;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
}

.success-message
{
  font-size: 2em;
  color:#AEDD81;
}

.tiles-container .padded-value
{
  padding: 0.25rem 0.25rem 1.5rem 2.5rem; 
  color: #fff;
  font-weight: bold;
  font-size: 1rem;
  background-color: rgb(49, 130, 189);
}

/*Recharts - fixing*/

.TopLongContainer .recharts-legend-wrapper {
  bottom: -18px !important;
}
.recharts-layer.recharts-cartesian-axis-tick text {
  font-size: 0.75rem;
  font-weight: bold;
}
.grid-star-item {
  text-align: right;
  padding-right: 0.25rem;
}
.grid-star-item img {
  width: 14px;
}



.table-row
{
  padding: 5px;
  min-height: 20px;
}

.table-row.value
{
  font-weight: 700;
}

.table-column
{
  display: flex;
  flex-direction: column;
}

.table-row.side-heading
{
  background: #61dafb;
}

.table-row.heading
{
  background: #999;
}

.tiles-container-non-hover
{
  display: flex;
}
.table-column-group-content
{
  display: flex;
}

.table-column-group-title
{
  min-height: 30px;
}

.systemUsage .recharts-legend-item-text {
  color: rgb(136,136,136) !important;
}


.dropdown.filter .dropdown-content.open {
  padding: 0;
  background-color: #fff;
}

.dropdown.filter .dropdown-content.open .dropdownBody {
  padding: 0;
  text-align: left;
}

.dropdown.filter .dropdown-content.open .dropdownBody > div {
  padding: 1rem 1rem;
  border-bottom: 1px solid #bbb;
}

.dropdown.filter .dropdown-content.open .dropdownBody > div:hover {
  cursor: pointer;
  background-color: #F1F1F1;
}

.dropdown-content.open.sliderWraper .BottomButtons {
  background-color: transparent;
  border: none;
  padding-bottom: 0;
}
.sliderWraper .BottomButtons .button-main {
  align-self: flex-end;
  margin-left: auto;
}

.small-charts-bottom
{
  display: flex;
  flex-direction: row;
}

.sub-details-container
{
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 30px;
  flex-grow: 1;

}

.details-container{
  background: #97CDD9;
  display: flex;
  flex-direction: column;
  width: 100%;
  
}

.details-container div{
  flex-grow: 1;
 
}

.latest-systems-container .recharts-surface
{
  overflow: visible;
}

.pause-button
{
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 64px;
  cursor: pointer;
}

.signedin-label
{
  position: fixed;
  left: 0px;
  bottom: 0px;
  font-style: italic;
  color: grey;
}

.st0{fill:#737373;}
.st1{fill:#F25022;}
.st2{fill:#7FBA00;}
.st3{fill:#00A4EF;}
.st4{fill:#FFB900;}




.subrowcontainer
{
  display: flex;
  flex-direction: row;
}

.summary-holder
{
  margin-left: 5px;
}

.next-button
{
  cursor: pointer;
  position: absolute;
  bottom: -30px;
  right: 10px;
  width: 64px;

}
.chart-holder-with-buttons
{
  position: relative;
}


/* TABLE */
.base-data-grid {
  border-collapse: collapse;
}
.base-data-grid > thead tr th,
.base-data-grid > thead tr td {
  border-right: 2px solid #fff;
  line-height: 1;
}
.caseUploadWrapper .base-data-grid > thead tr th {
  padding-top: 0;
}
.caseUploadWrapper .child-table .base-data-grid>thead tr th {
  padding-top: 0.25rem;
}
.base-data-grid > tbody {
  background-color: #eee;
}
.base-data-grid > tbody > tr > td {
  border: 2px solid #fff;
}

.caseUploadWrapper .headerLabels > tr th:first-child{
  text-align: left;
  min-width: 8rem;
}
.caseUploadWrapper .headerLabels > tr input[type='checkbox'] {
  margin-right: 1rem;
  margin-left: 0.5rem;
}

.primaryColumnChild {
  display: flex;
  flex-direction: row;
}
.primaryColumnChild label {
  width: 3rem;
}

.caseNumberUpload {
  width: 1.5rem;
  font-size: 1.125rem;
}

.columnChildNote {
  font-style: italic;
  font-size: 0.875rem;
  font-weight: bold;
}
thead.headerLabels .study-id-column {
  font-weight: 900;
  font-size: 0.875rem;
  background: var(--swan) !important;
  padding: 0.5rem 0.25rem;
  max-width: 5rem;
  min-width: 4rem;
  width: 5rem;
  text-wrap: wrap;
  color: var(--GreenApple);
  border: 3px solid var(--GreenApple) !important;
}
.blank-study-id {
  display: inline;
  width: 80px;
  height: 61px;
}
.blank-study-id svg {
  width: 32px;
  height: 32px;
  -webkit-filter: drop-shadow( 1px 1px 1px rgba(0, 0, 0, .25));
  filter: drop-shadow( 1px 1px 1px rgba(0, 0, 0, .25));
}

.missing-thumbnail-simple {
  background-color: var(--snapper);
  font-size: 0.75rem;
  white-space: nowrap;
  padding: 0.5rem 0.125rem;
}
.missing-thumbnail-simple svg {
  width: 28px;
  height: auto;
  -webkit-filter: drop-shadow( 1px 1px 1px rgba(0, 0, 0, .25));
  filter: drop-shadow( 1px 1px 1px rgba(0, 0, 0, .25));
}

/* Grid report */
.createReport .primaryButton svg {
  width: 2rem;
  height: 2rem;
}

/*  THEAD */

thead.headerLabels th {
  color: var(--wolf);
  border-right: 1px solid #eee;
  font-size: 1rem;
  font-weight: 600;
  padding: 1rem 0.125rem 0;
  min-width: 4rem;
} 

thead.headerData tr > td {
  font-size: 12px;
  font-weight: 700;
  border-right: 2px solid #fff;
  color: var(--dolphin);
  margin: 0;
  padding-bottom: 2px;
}
thead.headerData tr > td > td {
  border-right: none;
}

::placeholder {
  font-size: 13px;
  width: 100%;
}

/* Table Filtering  */
.theadFilter {
  margin: 0; 
}
.theadFilter th {
  padding-top: 0.5rem; 
}
.theadFilter .textbox,
.theadFilter select {
  min-width: 1.5rem;
  max-width: 3.5rem;
  box-shadow: 1px 1px 4px 1px inset rgba(0,0,0,0.1);
  background-color: #fff;
  border: 1px solid #666666;
  border-radius: 0.125rem;
  margin: 0 0.25rem 0 0;
  font-size: 0.875rem;
  padding: 0.125rem;
}

.usersGridHolder .theadFilter select {
  max-width: 8.5rem;
}

.usersGridHolder .theadFilter .textbox {
  max-width: 8.5rem;
}

.base-data-grid > thead.theadFilter th,
.base-data-grid > thead.theadFilter + thead.headerLabels th,
.base-data-grid > thead.theadFilter + thead.headerLabels + thead.headerData td {
  background-color: #ccc;
  border-right: 2px solid #fff;
  min-width: 2rem;
  width: 2rem;
}

.base-data-grid > thead.theadFilter + thead th {
  padding-top: 0;
  padding-bottom: 0;
}

.intervalHolder {
  display: block;
}
.intervalBox {
  width: 2.5rem;
  font-size: 0.875rem;
  padding: 0.125rem;
  background-color: #fff;
  border: 1px solid #666666;
  border-radius: 0.125rem;
  box-shadow: 1px 1px 4px 1px inset rgba(0,0,0,0.1);
  overflow: visible;
}

input[name="quantityMin"] {
  float: left;

}
input[name="quantityMax"] {
  float: left;
  clear: both;
  margin-top: 0.25rem;
}

.messurementFilterContainer {
  display: flex;
  justify-content: center;
  max-height: 4rem;
}
.messurementFilterContainer .inputContainer {
  margin-left: 0.125rem;
  flex-direction: column;
}
.messurementFilterContainer .inputContainer .inputLabel {
  font-weight: normal;
  font-size: 14px;
  white-space: normal;
  max-width: 4rem;
  text-align: left;
}




.tableCommentsText {
  display: flex;
  height: 4rem;
  min-width: 6rem;
  margin: 0 0.125rem;
}

.grid-grouping-row {
  font-size: 1.25rem;
  text-align: left;
  background-color: #ccc;
  border-bottom: 1px solid #707070;
}

.grid-grouping-row td {
  padding-left: 0.5rem;
}

.data-grid-cell {
  min-width: 2rem;
  max-width: 12rem;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  margin: 0.5rem;
}

.gridCell-comments .data-grid-cell,
.gridCell-anatomicalLocations .data-grid-cell {
  text-align: left;
  max-width: 16rem;
}

.gridCell-otherComments .tableCommentsText {
  text-align: left;
  max-width: 16rem;
  width: 94%;
  display: block;
  margin-right: 2%;
  resize: none;
  border-style: outset;
  border: 1px solid #777;
}

.gridCell-starRating {
  min-width: 4.5rem;
}

/* child table */

tr.selected {
  background-color: var(--dolphin);
  box-shadow: 0 1px 3px rgba(0,0,0,0.3);
  z-index: 99;
  position: relative;
  font-weight: bold;
  color: var(--swan);
}
tr.selected + tr + tr {
  box-shadow: 0 -1px 3px rgba(0,0,0,0.3);
  position: relative;
}
.child-table thead {
  background-color: #cdcdcd;/**/
}
.child-table tbody {
  background-color: var(--swan);
}
.child-table tbody > tr > td {
  border: 2px solid #eee;
}

.child-table .data-grid-container {
  min-height:auto;
  position: relative;
}

.child-table tr.selected,
.child-table tr.selected + tr + tr {
  box-shadow: none;
  position: static;
}
.child-table tr.selected {
  background-color: var(--shark);
}

.child-table .data-grid-container table > thead th  {
  padding-bottom: 0.5rem;
}

.child-table .data-grid-container table .thumbItem {
  width: 12rem;
  height: 8rem;
  background-color: #fff;
}

.thumbItem .flowThumb
{
  background-color: #FFF;
}

.child-table .gridCell-anatomicalLocations .data-grid-cell,
.child-table .gridCell-anatomicalLocations {
  max-width: 8rem;
  width: 8rem;
}

.gridCell-mean,
.gridCell-pI,
.gridCell-dF {
  width: 2rem;
}

.gridCell-recordDate,
.gridCell-createdDate {
  min-width: 6rem;
  width: 6rem;
}

.child-table .base-data-grid tbody tr td.gridCell-subwaymap {
  min-width: 11rem;
  padding: 0.25rem;
}

.child-table .base-data-grid tbody tr td.gridCell-otherComments  {
  min-width: 6rem;
}

.child-table .base-data-grid tbody tr td.gridCell-measurementType {
  min-width: 4rem;
  width: 4rem;
}







/* .meanflow-thumbnail
{
  padding: 5px;
  max-width: 40px;
  max-height: 50px;
  margin: 2px;
  background-color: #00b53f;
} */

.thumbnail-holder {
  display: flex;
  justify-content: center;
}

.thumbItem {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    width: 4rem;
    overflow: hidden;
    background-color: #000;
    margin: 0.25rem;
    outline: var(--RavenDark) 1px solid;
    border-radius: 0.25rem;
    color: var(--Raven);
    font-weight: var(--FontWeightHeavy);
}

.thumbItem:hover {
    cursor: pointer;
    outline: 2px solid var(--shark);
}

.imaging-thumbnail-simple,
.tile-thumbnail-simple {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 4.5rem;
}

.tile-thumbnail-simple.thumbnail-other{
  color: #fff;
  justify-content: center;
}

.imaging-thumbnail img {
  min-width: 50px;
  min-height: 50px;
  max-width: 180px;
  max-height: 120px;
  justify-content: center;
  object-fit: contain;
}

.imaging-thumbnail-simple img {
  min-height: 2rem;
  max-width: 12rem;
  max-height: 3.25rem;
  object-fit: contain;
}

.meanflowThumbnail {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.meanflowThumbnail div {
  display: flex;
  flex-direction: column;
}

.thumbnail-secondaryindex {
  background-color: #ccc;
}

.thumbnails-container {
  display: flex;
  justify-content: center;
}

.thumbTitle {
  align-self: flex-start;
  padding-left: 0.25rem;
}

.meanflowThumbnailGreen  {
  background-color: var(--GreenApple);
}

.meanflowThumbnailGreen td {
  background-color: var(--GreenApple) !important;
}

.meanflowThumbnailYellow {
  background-color: var(--BananaClan) !important;
}
.meanflowThumbnailYellow td {
  background-color: var(--BananaClan) !important;
}

.meanflowThumbnailRed {
  background-color: var(--BernRed) !important;
}

.meanflowThumbnailRed td {
  background-color: var(--BernRed) !important;
}






.child-table {
  max-height: 400px;
  overflow-y: scroll;
  margin-top: -4px;
  margin-bottom: -1px;
}

.child-table::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  background-color: var(--dolphin);
  height: 400px;
  width: 6px;
  z-index: 999999;
  box-shadow: 2px 0 4px rgba(0,0,0,0.2);
}

tr.selected > td:first-child {
  border-left-color: var(--dolphin);
}

.child-table::-webkit-scrollbar { width: 12px;}

.child-table::-webkit-scrollbar-track { background: #f1f1f1;  }

.child-table::-webkit-scrollbar-thumb { background: var(--seal);  }

.child-table::-webkit-scrollbar-thumb:hover { background: var(--dolphin);  }




/* InfoBox */

.infoBoxContainer {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  position: fixed;
  right: 2rem;
  top: 2rem;
  position: fixed;
  right: 2rem;
  top: 2rem;
  align-items: stretch;
  padding: 0.5rem;
  background-color: rgba(244, 241, 241, 1);
  background-color: rgba(244, 241, 241, 1);
  border: 1px solid black;
  color: black;
  border-radius: 0.5rem;
  box-shadow: 0.5rem 0.5rem 10px rgba(0,0,0,0.3);
  min-width: 12rem;
  min-height: 6rem;
  z-index: 99999;
}
.infoBoxHeader {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgb(90, 90, 90);
}

.infoBoxHeaderTitle {
  font-weight: bold;
  margin: 0.5rem;
}
.infoBoxHeader .closeButton {
  border: none;
  background-color: rgba(244, 241, 241, 0);
}
.infoBoxBody {
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
  align-items: center;
}
.bodyContent {
  margin-left: 0.5rem;
}

.typeIcon {
  width: 3rem;
  height: 3rem;
}
.typeIcon svg {
  width: 3rem;
  height: 3rem;
}

.typeIcon svg path {
  fill: green;
}

/* default inputItems */
.inputContainer {
display:flex;
flex-direction: row;
justify-content: center;
align-items:flex-start;
}

.inputLabel {
  display:flex;
  margin-right: 3px;
  
}

.longtextbox {
  display:flex;
  background-color: rgb(240, 240, 240);
  border:1px solid black;
  min-width: 12rem;
  box-shadow: 0.2rem 0.2rem 0.2rem rgba(0,0,0,0.1);
  padding: 0px;
  margin: 5px;
}

.textbox {
  display:flex;
  background-color:rgb(240, 240, 240);
  border:1px solid black;
  min-width: 12rem;
  margin: 5px;
  box-shadow: 0.2rem 0.2rem 0.2rem rgba(0,0,0,0.1);
}

.dropdown {
  display:flex;
  background-color:rgb(240, 240, 240);
  border:1px solid black;
  
  margin: 5px;
  box-shadow: 0.2rem 0.2rem 0.2rem rgba(0,0,0,0.1);
}

/* Add Study popup */
.addStudyContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;

}
.addStudyHeader {
  display: flex;
  justify-content: left;  
}

.studyDropdownContainer {
  display: flex;
  justify-content: left;
  margin-left: 1rem;
}

.dropdown {
  display: flex;
  background-color: rgb(240, 240, 240);
  margin-left:0.5rem;
}

.addStudyBody {
  display: flex;
  justify-content: center;
  flex-direction: row;
  
}

.referenceValuesTitle {
  display: flex;
  justify-content: right;
  font-weight: bold;
  margin-right:0.5rem;
}

.addStudyFooter {
  display: flex;
  justify-content: right;
  
}

.createButton {
  display: flex;
  padding: 0.7rem;
  min-width: 10rem;
  background-color: white;
  border: 1px solid grey;
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.leftContainer {
  display: flex;
  flex-direction: column;
  justify-content: right;
  margin: 1rem;
  margin-top: 0.5rem;  
}

.studyformHeader {
  display: flex;
  justify-content: right;
}

.inputItems{
  display: flex;
  flex-direction: column;
  align-items:flex-end

}
.rightContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  margin: 1rem;
  margin-top: 0.5rem;
}
.workflowContainer{
  display: flex;
  flex-direction: column;
}
.workflowHeader{
  display: flex;
  justify-content: right;

}
.addWorkflow{
  display: flex;
  justify-content:space-between;
  flex-direction: row;

}


.protocolform{
  display: flex;
  flex-direction: column;
  justify-content: right;
  margin: 1rem;
  margin-top: 0.5rem;
}
.protocolformHeader{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  
}

.addOrRemoveButton{
  display: flex;
  margin:1px;
  background-color: white;
  border: 1px solid grey;
  border-radius: 3px;
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  margin-left:0.5rem;} 
  

.newStep{
  display: flex;
  flex-direction: row;
  align-items: center;
  
}
.newStepContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.newStepHeader{
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.labelgridName{
  display: flex;
  justify-content: left;
  margin-left: 10%;
}
.labelgridDesc {
  display: flex;
  margin-left: 15%;
  
}
.labelgridType {
  display: flex;
  justify-content: right;
  margin-right: 20%;
}
.newStepInputs {
  display: flex;
  width: fit-content;
}

.SelectionOptionsHolder {
  max-height: 200px; /* or any fixed height */
  overflow-y: auto;
  
}

.multipleSelectLabel{
    align-self: flex-start;
    color: #000;
    white-space: nowrap;
    width: min-content;
    border-radius: 0.75rem 2rem 2rem 0.75rem;
    padding: 0.125rem 1rem 0.125rem 0.25rem;
    margin: 0.2rem;
    font-weight: 600;
    font-size: 0.825rem;
    text-rendering: optimizeLegibility;
    word-break: break-all;
}

/* PERNILLES CSS */

.closeButton {
  display: flex;
  align-items: center;
  border: none;
  background: none;
  font-size: x-large;
  right: 0;
  top: 0;
  transition-duration: 0.2s;
  cursor: pointer;
  margin: 0;
  padding: 0.5rem;
  border-radius: 0.5rem;
  margin-left: auto;
  position: absolute;
}

.buttonIcon {
  width: 2rem;
  height: 2rem;
  padding: 0.25rem;
  color: #454545;
}
.buttonIcon:hover {
  stroke-width: 0.75rem;
  cursor: pointer;
  color:#000;
  stroke: #000;
  box-shadow: 1px 1px 6px rgba(0,0,0,0.5);
  background-color: #fff;
}

.closeButton .buttonIcon{
  width: 2rem;
  height: 2rem;
  border-color: transparent;
}

.closeButton:hover .buttonIcon{
  box-shadow: none;
  background-color: transparent;
}

.starRatingTitle .buttonIcon {
  color: #000;
  width: 1.5rem;
  height: 1.5rem;
  box-shadow: none;
  background-color: transparent;
}

.starRatingTitle {
  display: flex;
  align-items: center;
  justify-content: center;
}


.primaryButton {
  color: #454545;
  margin: 0.25rem;
  background: #f5f5f5;
  border-color: #bbb #777 #777 #bbb;
  border-radius: 0.25rem;
  border-width: 1px;
  font-size: var(--FontS);
  font-weight: 500;
  height: auto;
  transition-duration: 0.2s;
  cursor: pointer;
  padding: 0.25rem 0.75rem;
  box-shadow: 1px 1px 3px rgba(0,0,0,0.2);
  border-style: solid;
  text-wrap: nowrap;
}

.primaryButton:hover {
  background-color: #CDCDCD;
  color: #000;
}
.Tabish .primaryButton.Pressed,
.primaryButton.Pressed,
.primaryButton.Pressed:hover {
  background-color: var(--Tangerine);
  border-color: var(--Tangerine);
  color: #fff;
  font-weight: bold;
  border: 1px solid transparent;  
}

.primaryButton.actionButton {
  background-color: var(--Tangerine);
  color: #fff;
  font-weight: 600;
  border: 1px solid var(--BernRed);
}
.primaryButton.actionButton:hover {
  background-color: var(--BernRed);
  color: #fff;
  border-color: var(--BernRed);
}

.primaryButton[disabled],
.primaryButton[disabled]:hover {
  border-color: #AAA;
  background-color: #ddd;
  color: #777;
  pointer-events: none;
}

.primaryButton.NotButton,
.primaryButton.NotButton:hover {
  border-color: transparent;
  background-color: transparent;
  box-shadow: none;
  color: #454545;
  font-weight: normal;
}

.primaryButton.animatingButton {
  animation: changeColorLoading 1s infinite alternate;
  box-shadow: none;

}
  
@keyframes changeColorLoading {
  from {
      background-color: var(--swan);
      color: var(--shark);
  }
  to {
      background-color: var(--snapper);
      color: var(--Raven);
  }
}


.buttonContainer {
  display: flex;
  align-items: center;
  flex: 2;
}

.middleButtons {
  margin-left: auto;
  display: flex;
  flex-direction: row;
}

.tabsAndButtons {
  display: flex;
  flex: 2;
  flex-direction: column;
}
.Tabish {
  display: flex;
  flex-direction: row;
}
.Tabish .primaryButton {
  background-color: #fff;
  display: flex;
  align-self: end;
  border-radius: 0;
  margin-bottom: 0;
  margin-right: 0;
  border-bottom: none;
  padding: 0.5rem 0.75rem;
  line-height: 1;
  border-color: #777;
  font-weight: 500;
  box-shadow: none;
}

.Tabish .primaryButton.Pressed, 
.Tabish .primaryButton.Pressed:hover {   
  box-shadow: none;
  border-bottom: none;
  font-weight: bold;
}

.buttonContainer.buttonSettings {
  flex-grow: 0;
  margin-left: auto;
}
.buttonContainer.buttonSettings .primaryButton {
  align-self: stretch;
  margin: 0 0 0 auto;
  border-radius: 0;
  padding: 1rem;
  font-size: 1.825rem;
  background-color: transparent;
  border: 0;
  line-height: 0;
  box-shadow: none;
}
.buttonContainer.buttonSettings .primaryButton:hover {
  background-color: #ddd;
}

.popupContainer .primaryButton {
  font-size: 1.25rem;
}

/*FILTER*/

.primaryButton.filter svg {
  width: 28px;
  height: 28px;
}
.primaryButton.filter {
  padding-top: 0.5rem;
  padding-bottom: 0.25rem;
}
.primaryButton.filter.Pressed {
  background-color: var(--ActiveDodgerBlue);
  border-color: var(--ActiveDodgerBlue);
}
.primaryButton.filter.Pressed path {
  fill: var(--swan);
}



.transparentWrapper {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.8);
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  pointer-events: none;
}


/* popup css stuff */

.popupWrapper
{
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.7);
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px; 
  z-index: 1000;
}



.popupContainer {
  display: flex;
  flex-direction: column;
  max-height: 99%;
  min-height: 40%;
  min-width: 40%;
  max-width: 95%;
  border-color: #ececec;
  border-style: solid;
  border-width: thin;
  background-color: #fff;
  box-shadow: 0 0.25rem 1rem 0.25rem rgba(0, 0, 0, 1), 0 0.5rem 1rem 0.25rem rgba(0, 0, 0, 1);
  padding: 0;
  pointer-events: visible;
  position: relative;
}

.popupTopbar
{
  display: flex;
  justify-content: stretch;
  top: 0;
  background-color: #f1f1f1;
  z-index: 9;
  border-bottom: 1px solid #bbb;
  box-shadow: 0 3px 4px rgba(0,0,0,0.2);
}

.popupTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  top: 0;
  color: var(--shark);
  min-height: 3rem;
}

.popupTitle h1 {
  margin: 0.5rem 0;
  font-size: 1.75rem;
}

.popupText {
  max-width: 56rem;
  text-align: left;
  padding: 1rem;
}

.popupMainbar {
  display: flex;
  justify-content: center;
  position: relative;
  padding: 0;
  overflow: auto;
}

/* slideout css stuff */

.slideoutContainer {
  position: absolute;
  left: 100%;
  width: 25%;
  height: 100%;
  top: 0;
  border-color: #ececec;
  border-style: solid;
  border-width: thin;
  background-color: #fff;
  box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.2), 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.19);
  padding: 1rem;
  transition: transform 0.3s ease-in;
  z-index: 999;

  pointer-events: visible;
}

.slideoutContainer.active {
  transform: translateX(-100%);
}

.slideoutTopbar {
  display: flex;
  justify-content: stretch;
  position: relative;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.slideoutTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: large;
}

.slideoutMainbar {
  display: flex;
  align-items: flex-start;
}

.slideoutContent {
  display: flex;
  align-items: flex-start;
  flex: 1;
}

.menu {
  display: grid;
  row-gap: 1rem;
  column-gap: 0.25rem;
  margin-bottom: 0.5rem;
  flex-grow: 1;
}

.menuButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  font-size: x-large;
  min-width: 4rem;
  transition-duration: 0.2s;
  cursor: pointer;
}

.menuItem {
  display: flex;
  cursor: pointer;
  height: 2.5rem;
  align-items: center;
}

.menuItem:hover,
.menuItem:hover .buttonIcon {
  font-weight: bold;
  background-color: #f1f1f1;
  stroke-width: 0.5rem;
}
.dashboardItem
{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 1rem;
}

/*
.rowAlternate2 
{
  background-color: #CDCDCD;
}
*/

/*errorWindow Styling*/

.errorWrapper {
  display: flex;
  height: 100%;
  position: fixed;
  align-items: center;
  justify-content: center;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
}
.errorContainer{
display: flex;
flex-direction: column;
top: 20%;
background-color: #f1f1f1;
min-height: 150px;
max-height: 80%;
min-width: 30rem;
max-width: 40%;
box-shadow: 4px 2px 18px 0 rgba(0,0,0,0.3);
z-index: 999999999;
}
.errorTitle{
display: flex;
margin-left: 6%;
align-items: center;
text-align: center;
}
.errorHeader{
  border-bottom: 1px solid #aaa;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #d1d1d1;
}
.errorMessage{
  overflow: hidden;
  padding-left: 1rem;
}
.errorCloseButton{
  width: 2rem;
  height: 2rem;
  padding: 1rem;
}
.errorCloseButton:hover {
  background-color: #eee;
  cursor: pointer;
}
.errorBody{
  display: flex;
  flex-direction: row;
  padding: 1rem;
}
.errorText{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  overflow-x: hidden;
  overflow-y: auto;
  text-overflow: ellipsis;
}
.errorCustomMessage{
  font-size: 1rem;
  max-height: 8rem;

}
.errorIcon{
  font-size: 9rem;
  min-width: fit-content;
}
/*errorWindow done*/

/*confirmation window*/

.confirmationWrapper {
  display: flex;
  height: 100%;
  position: fixed;
  align-items: center;
  justify-content: center;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
}

.confirmationContainer {
  display: flex;
  flex-direction: column;
  background-color: #f1f1f1;
  min-height: 150px;
  max-height: 80%;
  min-width: 30rem;
  max-width: 40%;
  box-shadow: 4px 2px 18px 0 rgba(0,0,0,0.3);
  z-index: 999999999;
}

.confirmationHeader {
  border-bottom: 1px solid #aaa;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #d1d1d1;
  padding: 0.5rem 1rem;
}

.confirmationTitle {
  margin: 0;
  font-size: 1.2rem;
}

.confirmationCloseButton {
  width: 2rem;
  height: 2rem;
  padding: 0.5rem;
  cursor: pointer;
}

.confirmationCloseButton:hover {
  background-color: #eee;
}

.confirmationBody {
  display: flex;
  flex-direction: row;
  padding: 1rem;
}

.confirmationIcon {
  font-size: 4rem;
  min-width: fit-content;
  margin-right: 1rem;
}

.confirmationText {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  overflow-x: hidden;
  overflow-y: auto;
}

.confirmationMessage {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.confirmationButtons {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #aaa;
}

.confirmationButton {
  padding: 0.5rem 1rem;
  margin-left: 0.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.primaryButton.yes {
  background-color: #4CAF50;
  color: white;
}

.primaryButton.no {
  background-color: #f44336;
  color: white;
}

.primaryButton.cancel {
  background-color: #e7e7e7;
  color: black;
}

.primaryButton:hover {
  opacity: 0.8;
}

/*show more less btton*/
.show-more-button,
.show-less-button {
  background: none;
  border: none;
  color: blue;
  cursor: pointer;
  padding: 0;
  font-size: inherit;
  text-decoration: underline;
}
.show-more-button:hover,
.show-less-button:hover {
  color: darkblue;
} 
.trimText
{
  overflow-x: hidden;
}
.userWindowWrapper{
  padding: 1rem 0;
}
.usersGridHolder table {
  width: 100%;
  border-collapse: collapse;
}
.usersGridHolder th,
.usersGridHolder td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
.usersGridHolder .buttonIcon:hover {
  font-size: large;
}
.usersGridHolder th {
  background-color: #f2f2f2;
  font-weight: bold;
}
.usersGridHolder tr:hover {
  background-color: #f5f5f5;
}
.windowForm {
  padding: 1rem;
  display: grid;
  gap: 16px;
}
.windowForm .inputContainer {
  justify-content: space-between;
}
.windowForm input {
  padding: 8px;
  border: 1px solid #ddd;
}
.windowFooter {
  display: flex;
  justify-content: center;
  gap: 16px;
}
.windowFooter button {
  padding: 8px 16px;
  background-color: #f2f2f2;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
}
.windowFooter button:hover {
  background-color: #e0e0e0;
}

/* star rating */

.starContainer {
  display: flex;
  justify-content: center;
}
.starContainer .star {
  padding-left: 0.25rem;
}
.starContainer .star svg {
  width: 1rem;
}
.gridCell-starRating .starContainer .star {
  padding-left: 0.125rem;
}
.gridCell-starRating .starContainer .star svg {
  width: 0.75rem;
}

.userWindowFooter{
  min-height: 3rem;
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 16px;
}
.userWindowFooter button {
  padding: 8px 16px;
  background-color: #f2f2f2;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
}
.userWindowFooter button:hover {
  background-color: #e0e0e0;
}
.datagridStars{
  display: flex;
  flex-flow: column;
}

/* Progressbar */

.uploadProgressbar {
  align-self: center;
    display: flex;
    position: fixed;
    bottom: -1px;
    background: #fff;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem 0.5rem 0 0;
    box-shadow: 0 -3px 5px rgba(0, 0, 0, 0.2);
}
.uploadProgressbar .progressbarWrapper .progressbarValue {
  display: none;
}
.uploadProgressbar .progressbarWrapper .progressbarContainer {
  width: auto;
  min-width: 8rem;
}
.MainProgressbar .progressbarWrapper {
 font-size: 1rem; 
 line-height: 1;
 overflow: visible;
} 
.progressbarValue { 
  max-width: 94px;
  color:black;
  font-weight: bold;
  display: flex;
  justify-content: right;
  margin-left: 8px;
}
.progressbarValue div{ 
  font-size: 14px;
  font-weight: bold;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.5);
  background-color: var(--GreenApple);
  color: #fff;
  padding: 0.25rem;
  margin-bottom: -2px;
  z-index: 9;
  border-radius: 4px 4px 0 0;
  border: 1px solid var(--GreenAppleDark);
  border-bottom:0 ;
}
.progressbarContainer {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 100%;
}
.progressbarHolder {
  flex-grow: 1;
  height: 1.5rem;
  max-width: 70%;
}
.progressbar {
  height: 1.5rem;
  background-color: white;
  border-radius: 0;
  border: 1px solid var(--GreenAppleDark);
  border-style: outset;
  overflow: hidden;
}
.progressbarValueWidth {
  height: 100%;
  background-color: var(--GreenApple);
  border-radius: 0;
  font-size: 1.125rem;
  font-weight: normal;
}
.maxValue {
  margin-left: 0.125rem;
  font-weight: bold;
}
.progressbarTitle {
  margin-top: 2rem;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
}
/*upload window*/

.uploadUserScreen {
  display: flex;
  padding: 4rem;
}
.UploadGuide {
  flex:3;
}
.uploadButtonWrap {
  flex: 2;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 2rem 8rem;
}
.uploadButtonWrap button{
  padding: 2rem;
  color: var(--swan);
  background-color: var(--Tangerine);
  font-weight: bold;
  font-size: var(--FontXL);
  border: none;
}
.caseUploadForm h3 {
  margin: 0 0 0.5rem 0;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1;
}
.caseUploadForm {
  display: flex;
  text-align: left;
  background-color: #E0E0E0;
  box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.2) inset;
}
.caseUploadForm .uploadBox {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #fff;
  padding: 0.5rem 0.5rem 0.25rem 0.5rem;
  margin: 0.875rem 0.5rem;
  max-height: 176px;
  min-height: 176px;
  justify-content: space-evenly;
}
.uploadFormLeft {  max-width: 26%; }
.uploadFormCenter {  max-width: 28%; min-width: 25rem;}

.uploadBox.uploadFormRight {
  
}


.uploadBox label {
  margin: 0;
  display: flex;
  font-size: 1rem;
}
.uploadBox label:last-child {
  margin-bottom: 0;
}

.uploadBox input {
  background-color: #eee;
  border-width: 1px;
  margin: 0;
  max-width: 60%;
  width: 100%;
  padding: 0.125rem;
  font-size: 0.875rem;
  font-weight: bold;
  align-self: end;
}
.uploadBox label span {
  margin: 0 0.5rem 0 0;
  width: 33%;
  min-width: 8rem;
  padding: 0.125rem 0 0 0;
  text-align: right;
  line-height: 1;
}
.uploadBox em {
  font-weight: 600;
  max-width: 60%;
  width: 100%;
  margin-top: 1px;
}
.uploadData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1rem;;
}

.uploadFormCenter.uploadBox label {
  flex-flow: row-reverse;
  cursor: pointer;
}
.uploadFormCenter.uploadBox label:hover,
.uploadFormCenter.uploadBox label:focus {
  cursor: pointer;
  text-decoration: underline;
}
.uploadFormCenter.uploadBox label span {
  margin: 0 .5rem 0 0.5rem;
  text-align: left;
  width: 90%;
}

.uploadProgressbar .progressbarWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.caseUploadForm .uploadBox.uploadFormRight {
  /* padding: 0; */
}
.innerBox {
  padding: 0 0.25rem;
  flex: 1;
  height: 100%;
  margin: 0;
  font-size: 1rem;
  display: flex;
  align-items: center;
}

.innerBox p {
  margin: 0.125rem 0 0.5rem 0;
}

.canClose {
  border: 0.25rem solid #00C681;
  justify-content: center;
}
.uploadCompleted {
  border: none;
  background-color: #00C681;
  font-weight: bold;
}
.uploadCompleted .textCompleted {
  flex: 4;
}
.uploadCompleted .gg-check {
  flex: 1;
}
.normalBox {
  flex-direction: column;
  align-items: start;
}
.doNotClose {
  background-color: var(--BernRed);
  color: #fff;
  line-height: 1;
}
.uploadProgressbar .doNotClose {
  display: block;
}

/*home window*/
.pieChart
{
  height: 500px;
  width: 500px;
}
.loadingHolder
{
  background-color: white;
    opacity: 0.9;
    position: absolute;
    left: 0px;
    top: 0px;
    right: 100%;
    bottom: 100%;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
}

.thumbnail-container
{
  position: relative;
}

.thumbItem svg .TTFM-class
{
  stroke-width: 1;
}

.loadingHolder .loadingImage{
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}




/* **** PRINT **** */


/* HIDE while printing */

.subtitleAction {
  display: flex;
}
.subtitleAction h2 {
  margin: 1rem 0 0;
}
.subtitleAction button {
  margin-left: auto;
  margin-right: 0;
  min-width: 8rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.subtitleAction button svg {
  width: auto;
  height: 2rem;
}

#section-to-print {
  display: block;
  overflow: visible;
  border: none;
  padding: 8px;
  width: 764px;
  outline: 1px solid #767676;
  box-shadow: 0 0 8px rgba(0,0,0,0.5) inset;
}

#section-to-print table {
  border-collapse: collapse;
  table-layout: fixed;
  text-align: left;
}

#section-to-print .header-details .header-icon{
  width: 136px;
}

#section-to-print .header-icon svg {
  height: 18px;
  width: auto;
}

#section-to-print .header {
  font-size: 2.25rem;
  color: var(--Tangerine);
  }


  #section-to-print .header-details {
    padding-bottom: 0;
  }

  #section-to-print .main-details {
    padding-top: 4px;
  }

  #section-to-print .thumbnails-details,
  #section-to-print .holderIllustrationContainer {
    padding-top: 36px;
  }
  
 


#section-to-print table.row-item,
.blockTable,
.patientTable {
    width: 100%;
}
#section-to-print .idTable .item-value {
  font-weight: bold;
}
#section-to-print .report-measurement:nth-child(even) > tr td {
  background-color: #eeeeee;
}
#section-to-print tr.vertical-seperator {
  border-top: 1px dashed gray;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  padding: 0.25rem 0;
  margin: 0;
}

  #section-to-print tr.vertical-seperator td {
      height: 0.5rem;
  }




  

  #section-to-print .header-icon {
      font-size: 1rem;
  }

  #section-to-print .reportNumber {
      float: right;
      clear: both;
      font-size: 0.75rem;
      margin-bottom: 0.125rem;
  }

  #section-to-print .top-row svg {
      float: right;
      clear: both;
      width: 128px;
  }

  #section-to-print .infoRow > td {
      padding-bottom: 0.5rem;
  }

  #section-to-print .infoRowLeft {
      width: 55%;
  }

  #section-to-print .infoRowRight {
      width: 40%;
      padding-left: 5%;
      vertical-align: top;
  }



  #section-to-print tr.detail-item {
      margin: 0;
  }
  #section-to-print td.item-label {
      width: 128px;
      font-weight: bold;
      font-size: 16px;
      vertical-align: top;
      padding: 4px 0;
      line-height: 14px;
  }
  #section-to-print td.item-value {
      font-size: 16px;
      padding: 4px 0;
      line-height: 14px;
  }
  #section-to-print .mainDataTable td.item-label  {
    width: 50%;
  }
  #section-to-print .thumbItem {
    display: inline-block;
    width: 200px;
    background-color: #fff;
    margin: 0;
    outline: #777 1px solid;
    text-align: center;
  }
  #section-to-print .thumbItem .thumbnail-container svg {
    width: 192px;
  }
  #section-to-print .thumbnails-container {
      width: 200px;
  }
  #section-to-print tr.thumbnail-item.infoRow {
      padding: 0;
  }
  #section-to-print tr.thumbnail-item.infoRow > td {
          padding-top: 2rem;
          vertical-align: top;
      }

      #section-to-print tr.thumbnail-item img {
      width: 100%;
  }

  #section-to-print tr.thumbnail-item > td.infoRowLeft {
      padding-left: 0;
      width: 70%;
  }

  #section-to-print tr.detail-item.bigRow td {
      font-size: 1.25rem;
      font-weight: 700;
      border-bottom: 1px dashed gray;
  }
















@media print {

  #section-to-print {
    visibility: visible;
    width: auto;
    padding: 0;
    box-shadow: none;
    outline: none;
  }

  html body .mainContainer,
  html body .data-grid-container,
  html body .superHeader,
  html body .popupTopbar,
  html body .popupTitle,
  html body .button-main,
  html body .subtitleAction {
    display: none;
  }
  
  html body .transparentWrapper {
    display: block;
    position: relative;
    background-color: #ffffff;
  }
  html body .popupContainer {
  display: block;
  max-width: initial;
  max-height: initial;
  min-width: initial;
  min-height: initial;
  border: none;
  box-shadow: none;
  }
  html body .popupMainbar {
    display: block;
    padding: 0;
    overflow: visible;
  }




    



   


}
/* #report-window
 {

} */


.loadingHolder
{
  background-color: white;
    opacity: 0.9;
    position: absolute;
    left: 0px;
    top: 0px;
    right: 100%;
    bottom: 100%;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.thumbnail-container
{
  position: relative;
}
.loadingHolder .loadingImage{
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}
/*Quotes*/
.quoteContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  font-style: italic;
  font: xx-large;
  width: 100%;
  height: fit-content;
  
  margin-top: 6rem;
}
.quote{
  display: flex;
}
.tileAnatomicalLabel {
    background-color: #fff;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    padding: 2px;
    font-size: 0.75rem;
    font-weight: 700;
    margin-bottom: 0;
}
.ratingContainer {
 display: flex;
 flex-direction: row;
}
.ratingContainer .rightContainer {
  display: flex;
  flex-direction: column;
  border: 1px solid black;
}
.ratingContainer .leftContainer{
  display:flex;
  flex-direction:column;
  border: 1px solid black; 
}
.ratingContainer .leftContainer .leftContent{
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 0.5rem;
}
.ratingContainer .rightContainer .rightContent{
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 0.5rem;
}
.newLine{
display: flex;
justify-content: space-between;
padding: 0.5rem;
}
.newLine .plus{
  color:green;
}
.newLine .minus{
  color:red;
}
.drag-bar-container {
  margin-top: -50px;
}
.drag-bar-container.chunky-dragbar {
  margin-top: -70px;
}
.track,
.track-inset,
.track-overlay {
  stroke-linecap: round;
}
.track {
  stroke: var(--Raven);
  stroke-opacity: 0.3;
  stroke-width: 10px;
}
.chunky-dragbar .track{
  stroke-width: 20px;
}
.track-inset {
  stroke: var(--thunderfox);
  stroke-width: 8px;
}
.chunky-dragbar .track-inset{
  stroke-width: 16px;
}
.handle-joint
{
  stroke: var(--Lacustral);
    stroke-width: 10px;
}
.chunky-dragbar .handle-joint{
  stroke-width: 20px;
}
.track-overlay {
  pointer-events: stroke;
  stroke-width: 50px;
  stroke: transparent;
  cursor: crosshair;
}
.chunky-dragbar .track-overlay{
  stroke-width: 50px;
}
.handle {
  fill: var(--silverfox);
  stroke: var(--Lacustral);
  stroke-opacity: 1;
  stroke-width: 1.25px;
}
.chunky-dragbar .handle{
  stroke-width: 2.5px;
}




.axis-default line {
  stroke: var(--dolphin);
}

.axis-default path {
  stroke: var(--dolphin);
}

.axis-default text {
  font-weight: 600;
  fill: var(--shark);
  font-size: larger;
  font-family: 'Segoe UI', Helvetica, Arial, Tahoma, Geneva, Verdana, sans-serif;
}

.imagingFullPreview
{
  width: 1024px;
  height: 640px;
}
.imagingFullPreview img
{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
/*export window*/
.exportbuttons{
  display: flex;
  justify-content: right;
  padding-right:1rem;
  padding-top:1rem;
}
/*export window*/
.exportbuttons{
  display: flex;
  justify-content: right;
  padding-right:1rem;
  padding-top:1rem;
}

/*Quotes*/
.quoteContainer
{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0;
  font-style: italic;
  font: xx-large;
  width: 100%;
  height: fit-content;
  margin-top: 6rem;
}

.quote
{
  display: flex;
}

.chartHolder
{
  position: relative;
  width: 50%;
}

.chartHolderStreach
{
  position: relative;
}

/* pager */

/* main-table margin-bottom*/
table.base-data-grid {
  margin-bottom: 3rem;
  min-width: 100%;
} 
.child-table table.base-data-grid {
  margin-bottom: 0;
} 







.pager {
  align-items: flex-end;
  justify-content: center;
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: var(--silverfox);
  border-top: 1px solid var(--seal);
  box-shadow: 0 -1px 3px rgba(0,0,0,0.2);
  z-index: 9999;
}

.pageNav button {
  min-width: 2rem;
  min-height: 2rem;
  margin: 0.25rem 0.125rem;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.5rem;
  font-size: 1.25rem;
  cursor: pointer;
  padding: 0.25rem;
  transition-duration: 0.2s;
}
.pageNav.pageSelected button,
.pageNav.pageSelected button:hover {
  background-color: var(--Tangerine);
}
.pageNav button:hover {
  cursor: pointer;
  border-color: #999;
  background-color: #bbb;
}

.child-table .pager {
  position: sticky !important;
  bottom: 0;
  z-index: 9998;
  background-color: var(--dolphin);
  border-top: 1px solid var(--Raven);
}
.child-table .pager .pageNav button {
  color: var(--swan);
  font-weight: bold;
}


.data-grid-container {
display: flex;
flex-direction: column;
justify-content: space-between;
  min-height: 90vh
}

/* tile background colors for feedback dependent on values */

.tile-greenlight {
  background-color: var(--GreenApple);
}

.tile-yellowlight {
  background-color: var(--BananaClan);
}

.tile-no-data {
  background-color: var(--swan);
}

.tile-axis-label {
  font-size: var(--FontS);
}


/* standard tile layout and fonts */

.tile-container {
  position: relative;
  overflow: hidden;
  display: flex;
  margin-bottom: 4px;
  margin-top: 0;
  max-width: 960px;
}

.ttfm-tile-container > div {
  display: flex;
  flex-direction: column;
}

.gauge-mode .ttfm-tile-container .tile-title  {
  display: flex;
  flex-direction: row;
}
.gauge-mode .ttfm-tile-container .tile-content  {
  display: flex;
  flex-direction: row;
  margin-top: -8px;
}
.gauge-mode .ttfm-tile-container .tile-value   {
  flex: 4;
}
.gauge-mode .ttfm-tile-container .tile-uom {
  flex: 1;
  align-self: center;
  font-weight: var(--FontWeightVeryHeavy);
  line-height: 20px;
}


.TTFM-tile-narrow {
  color: var(--Raven);
  text-decoration: none;
  min-width: 100px;
  flex-grow: 1;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  max-height: 200px;
}
.TTFM-tile-basic {
  color: var(--wolf);
  text-decoration: none;
  min-width: 120px;
  /* min-height: 120px; */
  padding: 0px 8px 0px 8px;
  border-radius: 0;
  cursor: pointer;
  max-height: 200px;
  position: relative;
  flex-grow: 1;
}
.tile-mode .TTFM-tile-basic {
  min-height: 120px;
}
.TTFM-tile-wide {
  color: var(--wolf);
  text-decoration: none;
  min-width: 150px;
  flex-grow: 1;
  padding: 0;
  border-radius: 0;
  cursor: pointer;
  max-height: 200px;
  position: relative;
  /* min-height: 120px; */
  height: 100%;
}
.mini-TTFM-tile-basic {
  color: var(--wolf);
  text-decoration: none;
  min-width: 46px;
  min-height: 23px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-left: 3px;
  margin-right: 3px;
}
/* In a compare mode the tiles need to be narrower */
.compare-column-wrapper .mini-TTFM-tile-basic {
  max-width: 50px;
}
.compare-thumbnail-holder {
  display: flex;
}
.tile-redlight {
  background-color: var(--BernRed);
}
.tile-title {
  font-size: var(--FontL);
  font-weight: var(--FontWeightHeavy);
}
.tile-title-right.BPM-value {
  position: absolute;
  bottom: 0;
  right: 2px;
  display: flex;
  flex-direction: column;
  max-width: 4rem;
  font-size: 1.25rem;
  font-weight: var(--FontWeightVeryHeavy);
}
.tile-title-right.BPM {
  font-weight: var(--FontWeightHeavy);
  font-size: var(--FontS);
}
.tile-value {
  font-size: var(--FontLL);
  font-weight: var(--FontWeightHeavy);
  left: 32px;
  bottom: 0px;
  line-height: 48px;
  text-align: right;
}
.tile-value-input {
  background: none;
  background-color: var(--swan);
  opacity: 0.8;
  font-size: var(--FontXL);
  font-weight: var(--FontWeightMedium) !important;
  font: var(--MainFont) !important;
  border: none;
  outline: none;
  margin-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  max-width: 90px;
}
.tile-value-container-no-edit {
  background-color: var(--swan);
  opacity: 0.8;
  margin-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
}
.tile-value-container-no-edit-no-color {
  background-color: var(--silverfox);
  opacity: 1;
}
.tile-uom {
  font-size: var(--FontS2);
  font-weight: var(--FontWeightMedium);
  color: var(--Raven);
  max-width: 3rem;
  word-wrap: break-word;
  margin-top: -0.5rem;
  line-height: 1;
  align-self: center;
  margin-left: auto;
}
.tile-uom-extended {
  font-size: var(--FontS2);
  font-weight: var(--FontWeightMedium);
  float: right;
  padding-top: -22px;
  padding-right: 0px;
}
.gauge-container {
  position: relative;
  overflow: hidden;
  display: flex;
  margin-bottom: 4px;
}
.TTFM-gauge-narrow {
  color: var(--Raven);
  text-decoration: none;
  min-width: 100px;
  flex-grow: 1;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  max-height: 200px;
}
.TTFM-gauge-basic {
  color: var(--Raven);
  text-decoration: none;
  min-width: 120px;
  min-height: 120px;
  padding: 0 8px;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  width: 174px;
}
.gauge .arc path {
  stroke: #fff;
  stroke-width: 2;
}
.left-tiles-container {
  display: flex;
  flex-direction: column;
  max-width: 190px;
}
.gauge-subvalue {
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: var(--dolphin);
}
.gauge-subvalue {
  position: absolute;
  right: 10px;
  bottom: 10px;
}
.TTFM-gauge-wide {
  color: var(--Raven);
  text-decoration: none;
  min-width: 150px;
  flex-grow: 1;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  max-height: 400px;
  position: relative;
  min-height: 120px;
}
.gauge-top {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mini-TTFM-gauge-basic {
  color: var(--Raven);
  text-decoration: none;
  min-width: 46px;
  min-height: 23px;
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 8px;
  margin-right: 8px;
}
.gauge-title {
  font-size: var(--FontL);
  font-weight: var(--FontWeightHeavy);
  align-self: flex-start;
  line-height: 24px;
}
.gauge-middle {
  width: 172px;
}
.ttfm-gauge-container {
  background-color: var(--swan);
  margin: 0 4px;
  flex-grow: 1;
  max-height: 300px;
  position: relative;
}
.ttfm-gauge-container::after {
  display: inline-block;
  content: "";
  width: 1px;
  height: 160px;
  background-image: linear-gradient(#dedede, #fff);;
  position: absolute;
  right: 0;
  top: 0;
}
.ttfm-gauge-container:last-child::after {
  display: none;
}

.gauge-title-right {
  float: right;
}
.gauge-value {
  font-size: var(--FontLL);
  line-height: 44px;
  font-weight: var(--FontWeightHeavy);
  vertical-align: bottom;
}
.gauge-value-input {
  background: none;
  font-size: var(--FontXL);
  font-weight: var(--FontWeightMedium);
  border: none;
  outline: none;
  max-width: 60px;
}
.gauge-uom {
  font-size: var(--FontS1);
  font-weight: var(--FontWeightHeavy);
  color: var(--Raven);
  line-height: 16px;
}
.gauge-uom-extended {
  font-size: var(--FontS1);
  font-weight: var(--FontWeightVeryHeavy);
  float: right;
  padding-top: -22px;
  padding-right: 0px;
}
.ttfm-tile-reference-values-expanded {
  height: 200px;
  border-radius: 4px;
  min-width: 120px;
}
.ttfm-tile-container {
  background-color: var(--swan);
  margin: 0 0 0 2px;
  flex-grow: 1;
  display: flex;
  align-content: stretch;
  flex-direction: column;
  max-height: 88px;
  min-width: 11rem;
  width: 11.875rem;
}
.tile-mode .tile-content {
  display: flex;
}
.tile-mode .tile-content .tile-value{
  width: 7rem;
  margin: -0.25rem 0 0 1rem;
  text-align: center;
}
#ttfm-tile-MAP-TTFM .tile-value {
  height: 52px;
  max-width: 92px;
}
.ttfm-gauge-reference-values-expanded svg {
  position: absolute;
  left: 0px;
}
.gauge-bottom, .gauge-title {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.gauge-bottom {
  transform: translate(0, -12px);
}
svg.gauge .label text {
  font-weight: var(--FontWeightVeryHeavy) ;
  transform: rotate(0deg) translate(-56px, -80px);
}
svg.gauge .label text + text {
  transform: rotate(0deg) translate(40px, -80px);
}
svg.gauge .arc {
  transform: translate(93px, 82px);
}
svg.gauge .pointer {
  transform: translate(93px, 79px);
  stroke: #fff;
  stroke-width: 2px;
}
.ttfm-gauge-reference-values::after {
  display: inline-block;
  content: "";
  width: 18px;
  height: 18px;
  background-color: rgba(0,0,0,1);
  border-radius: 24px;
  position: absolute;
  transform: translate(-10px, 70px);
}
.ttfm-tile-container-stacked {
  display: flex;
}
.RED-class {
  stroke-dasharray: 4;
}
/* tabColoring */
.tabColoring {
  display: flex;
  max-width: 24rem;
  flex-wrap: wrap;
  word-break:break-all;
}
.gridCell-measurements {
  max-width: 24rem;
  word-break: break-all;
}
.tagsWrapper {
  display: flex;
}
.labelInDataGrid {
  align-self: flex-start;
  color: #000;
  white-space: nowrap;
  width: min-content;
  border-radius: 0.75rem 2rem 2rem 0.75rem;
  padding: 0.125rem 1rem 0.125rem 0.25rem;
  margin: 0.2rem;
  font-weight: 600;
  font-size: 0.825rem;
  text-rendering: optimizeLegibility;
  word-break: break-all;
}

.vascular .gauge-middle {
  position: relative;
}

.vascular .gauge-bottom {
  transform: translate(102px, -6px);

}

.vascular .gauge .arc,
.vascular .gauge .pointer {
  transform: translate(188px, 82px);
}

.vascular .ttfm-gauge-reference-values::after {
  transform: translate(92px, 72px);
  width: 20px;
  height: 20px;
}


/*Label light?
.labelInDataGrid {
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.4);
  font-weight: bold;
}
*/

/*
.chartDropdown {
  display: flex;
  margin-left: 2rem;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
  color: #fff;
  font-weight: 700;
  font-size: 0.875rem;
}
*/

.thumbnailError
{
  text-align: center;
    padding-top: 40px;
    color: var(--snapper);
}
/* DASHBOARD MAINPAGE */

.dashboardBody {
  display: flex;
  flex-wrap: wrap;
}

/* --------------------------------------------------------------- */

.visual-row-container {
  min-width: 12rem;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  border-bottom: 1px dashed var(--thunderfox);
}
.visual-row-container.selected {
  background: var(--Cantalight);
}
.visual-graft-row {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  min-height: 72px;
}
.visual-graft-column {
  position: relative;
  /* flex-grow: 1;
  min-width: 70px; 
  max-width: 150px;  */
  flex-basis: 100px;
}
.visual-graft-column.is-source {
  flex-basis: 76px;
  max-width: 140px;
}
.visual-graft-column.av-access-item {
  flex-basis: 100px;
}
.reporting-container .visual-graft-column.extended {
  flex-basis: 200px;
}
.reporting-container .visual-graft-column.extended-large {
  flex-basis: 240px;
  height: 70px;
}
.reporting-container .visual-graft-column
{
  min-width: 130px;
}
.extended .visual-graft-button {
  min-width: 6rem;
}
.reporting-container .visual-graft-column.extended-large .visual-graft-button {
  height: auto;
  top: auto;
}

.plain-container .visual-graft-row:first-child .visual-graft-column.is-source {
  /*flex-basis: 82px;*/
}

/*
.visual-graft-column.is-source + .visual-graft-column {
  left: -24px;
}
*/
.reporting-container .visual-graft-button 
 {
   padding: 0.5rem 0.25rem 0.5rem;
   /*left: auto;*/
   right: 0px;
 }
.reporting-container .visual-graft-column
 {
   flex-basis: 140px;
   
 }
.is-source .visual-graft-button {
  left: calc(50% - 50px);
}
.visual-graft-button.selected {
  background-color: var(--DarkCantaloupe);
  color: var(--wolf);
  font-weight: var(--FontWeightHeavy);
  box-shadow: 1px 1px 5px var(--seal);
}
.visual-graft-button.is-source {
  min-width: 4rem;
}
.visual-graft-button.is-blank-item
{
  min-width: 8rem;
  left: 30px;
}
.report-line .visual-graft-column.is-blank-item{
  flex-basis:180px;
}

.visual-conduit-cross {
}

.visual-conduit-cross-top {
  position: absolute;
  left: 15%;
  height: 50%;
  width: 6px;
}
.visual-conduit-cross-bottom {
  position: absolute;
  left: 15%;
  top: 50%;
  height: 50%;
  width: 6px;
}
.visual-conduit-cross-left {
  position: absolute;
  top: 50%;
  height: 6px;
  width: 36%;
}
.visual-conduit-cross-right {
  position: absolute;
  left: 15%;
  top: 50%;
  height: 6px;
  width: 90%;
}
.heart-conduits-legend {
  position: absolute;
  bottom: 10px;
  z-index: 1000;
}
.legend-conduit-item {
  text-align: left;
}
.legend-conduit-item-line {
  position: relative;
  display: inline-block;
  min-height: 50px;
  min-width: 50px;
}
.legend-conduit-item-text {
  margin-left: 10px;
  vertical-align: top;
  margin-top: 15px;
  display: inline-block;
  font-size: var(--FontM);
}
.heart-conduits-legend .visual-conduit-cross-left {
  height: 14px;
}

.heart-conduits-legend .visual-conduit-cross-right {
  height: 14px;
}


/* --------------------------------------------------------- */
.heart-canvas {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  pointer-events: none;
  z-index: 4;
}

.heart-canvas-Reporting {
  /* position: absolute;
  top: 49px;
  left: 0px;
  height: 100%;
  width: 60%;
  pointer-events: none;
  z-index: 4; */
}
.heart-targets-display {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  pointer-events: none;
  z-index: 3;
  text-align: center;
}
.heart-targets-display-Reporting {
  /* left: 0px;
  top: 0px;
  width: 60%; */
}
.heart-target-image-container {
  height: 100%;
  width: 100%;
}
.heart-targets-display-Reporting .heart-target-image-container {
  /* margin-left: -659px;
  margin-top: 184px; */
}
.heart-target-image {
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0px;
  /* overflow: hidden; */
  /* width: 100%; */
  height: 100%;
}
.heart-3d-interactive {
  position: absolute;
  top: 90px;
  z-index: 30;
}
.target-right {
  position: absolute;
  right: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  height: 100%;
}
/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
.conduit-container {
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0px;
  margin: 0px;
}
.heart-drawing-container {
  padding: 14px;
  justify-content: center;
  position: absolute;
  top: 10px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.heart-image-container {
  position: absolute;
  top: 0;
  left: 0px;
  height: var(--HeartHeight);
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 4;
  background-image: url("/public/heartmodel.png"); 
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.65;
  background-size: contain;
}
.heart-front-veins {
  position: absolute;
  top: 0;
  left: 0px;
  height: var(--HeartHeight);
  width: 100%;
  height: 100%;
  pointer-events: none;
   background-image: url("/public/heartfrontveins.png"); 
  background-repeat: no-repeat;
  background-position: center;
  /*opacity: 0.95;*/
  background-size: contain;
}
.heart-back-veins {
  position: absolute;
  top: 0;
  left: 0px;
  height: var(--HeartHeight);
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-image: url("/public/heartbackveins.png"); 
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.65;
  background-size: contain;
}
.heart-drawing-container-TTFM {
  margin-top: 0px;
}
.heart-drawing-container-Imaging {
  margin-top: 100px;
}
.heart-drawing-container-Reporting
{
  margin-top: 20px;
  margin-bottom: 60px;
}
.heart-image-container-nowall {
  background: none;
}
.peripheral-bypass-background
{
  /* background-image: url("/onboarding-PeripheralBypass.svg"); */
}
.av-access-background
{
  /* background-image: url("/onboarding-AVAccess.svg"); */
}
.heart-image-container-Reporting {
  padding: 14px;
  justify-content: center;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  /* top: 185px;
  width: 60%;
  left: -40px;
  background-position: center; */
}

/* Drawing layers display */

.drawingoverlay-layer1 {
  z-index: 1;
}
.drawingoverlay-layer2 {
  z-index: 2;
}
.drawingoverlay-layer3 {
  z-index: 3;
}
.drawingoverlay-layer4 {
  z-index: 4;
}
.drawingoverlay-layer5 {
  z-index: 5;
}
.drawingoverlay-layer6 {
  z-index: 6;
}
.drawingoverlay-layer7 {
  z-index: 7;
}
.drawingoverlay-layer8 {
  z-index: 8;
}
.illustrationContainer
{
  position: relative;
  height: 375px;
  width: 480px;
}
.holderIllustrationContainer
{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.chartDropdown
{
  display: flex;
  margin-left: 2rem;
}
.starIcon
{
  width: 2rem;
  height: 2rem;
  color: var(--Tangerine);
}
.scoreboardHeader
{
  display: flex;
  align-items: center;
}

/* DASHBOARD MAINPAGE */

.dashboardBody {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}






/* --------------------------------------------------------------- */



.visual-row-container {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  border-bottom: 1px dashed var(--thunderfox);
}
.visual-row-container.selected {
  background: var(--Cantalight);
}
.visual-graft-row {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  min-height: 3rem
}
.visual-graft-column {
  position: relative;
  /* flex-grow: 1;
  min-width: 70px; 
  max-width: 150px;  */
  flex-basis: 100px;
}
.visual-graft-column.is-source {
  flex-basis: 76px;
  max-width: 140px;
}
.visual-graft-column.av-access-item {
  flex-basis: 100px;
}
.reporting-container .visual-graft-column.extended {
  flex-basis: 200px;
}
.reporting-container .visual-graft-column.extended-large {
  flex-basis: 240px;
  height: 70px;
}
.reporting-container .visual-graft-column {
  min-width: 130px;
}
.extended .visual-graft-button {
  min-width: 8rem;
}
.reporting-container .visual-graft-column.extended-large .visual-graft-button {
  height: auto;
  top: auto;
}
.visual-graft-button {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5rem;
  color: var(--shark);
  text-decoration: none;
  background: var(--swan);
  padding: 0.5rem 0.125rem 0.5rem;
  height: 2.25rem;
  min-height: 2rem;
  border-radius: 8px;
  text-align: center;
  position: absolute;
  left: calc(50% - 25px);
  top: calc(50% - 25px);
  min-width: 3rem;
  vertical-align: middle;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: 2px solid var(--newDolphin);
  border: none;
}


/* .plain-container .visual-graft-row:first-child .visual-graft-column.is-source {
  /*flex-basis: 82px;*/

.plain-container .visual-graft-row:first-child .visual-graft-column.is-source .visual-graft-button.is-source {
  left: 0;
  position: relative;
  z-index: 999;
  max-width: 100%;
}

.previewWorklistHolder {
  display: flex;
  min-width: 15rem;
  border-left: var(--snapper) solid 2px;
  margin-left: 0.5rem;
  padding: 1rem;
}

.previewWorklistHolder .visual-row-container {
  width: 15rem;
}
.previewWorklistHolder .plain-container {
  width: 15rem;
}

/*
.visual-graft-column.is-source + .visual-graft-column {
  left: -24px;
}
*/



.reporting-container .visual-graft-button {
   padding: 0.5rem 0.25rem 0.5rem;
   right: 0px;
 }

.reporting-container .visual-graft-column {
   flex-basis: 140px;
 }


.is-source .visual-graft-button {
  left: calc(50% - 50px);
}
.visual-graft-button.selected {
  background-color: var(--DarkCantaloupe);
  color: var(--wolf);
  font-weight: var(--FontWeightHeavy);
  box-shadow: 1px 1px 5px var(--seal);
}
.visual-graft-button.is-source {
  min-width: 4rem;
}
.visual-graft-button.is-blank-item {
  min-width: 4rem;
  left: 30px;
}
.report-line .visual-graft-column.is-blank-item {
  flex-basis:180px;
}



/* Splash page */
.visual-conduit-cross-top {
  position: absolute;
  left: 15%;
  height: 50%;
  width: 6px;
}
.visual-conduit-cross-bottom {
  position: absolute;
  left: 15%;
  top: 50%;
  height: 50%;
  width: 6px;
}
.visual-conduit-cross-left {
  position: absolute;
  top: 50%;
  height: 6px;
  width: 36%;
}
.visual-conduit-cross-right {
  position: absolute;
  left: 15%;
  top: 50%;
  height: 6px;
  width: 90%;
}


.legend-conduit-item {
  text-align: left;
}

.legend-conduit-item-line {
  position: relative;
  display: inline-block;
  min-height: 50px;
  min-width: 50px;
}

.legend-conduit-item-text {
  margin-left: 10px;
  vertical-align: top;
  margin-top: 15px;
  display: inline-block;
  font-size: var(--FontM);
}

.heart-conduits-legend .visual-conduit-cross-left {
  height: 14px;
}

.heart-conduits-legend .visual-conduit-cross-right {
  height: 14px;
}
.topContent {
  display: flex;
  justify-content: space-around;
}

.bottomContent {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
}


.previewHolder {
  display: flex;
  flex-direction: row;
}

.previewDataHolder {
  display: flex;
  width: calc(100% - 13rem);
  padding: 2rem 0;
}
.previewDataHolder > .thumbnail-container {
  width: 960px;
  max-width: 960px;
  min-width: 960px;
}
.fullPreview .previewDataHolder
{
  width: 100%;
}
.previewDataHolder .previewDataHolder > .thumbnail-container {
  width: 1160px;
  max-width: 1160px;
  min-width: 1160px;
}



.ALHeader {
  display: flex;
  justify-content: flex-end;
}






.dashboardText {
  text-align: left;
  font-size: 1.25rem;
}
.illustrationWorklist {
  position: absolute;
  right: 0%;
  top: 0px;
  width: 30%;
  min-height: 100%;
  padding-left: 1rem;
  border-left: 3px solid var(--snapper);
}
.termsContainer {
  max-height: 40rem;
  overflow-y: auto;
  text-align: left;
  max-width: 45rem;
  padding: 1rem;
}
.termsAccept {
  margin-top: 2rem;
}
.termsAccept button {
  margin-left: 0;
}
.confirmButton {
  display: block !important;
  background-color: #1E6665;
  border: 2px solid #1C5351;
  color: #ffffff;
  font-weight: bold;
}
.spaceTop {
  margin-top: 1rem;
  text-align: left;
}
.subTotalDisplay {
  display: flex;
  justify-content: space-around;
}

.base-data-grid tfoot {
  border-top:  0.2rem solid var(--seal);
  border-bottom: 0.2rem solid var(--seal);
  background-color: var(--elephant);
  font-size: var(--FontS1);
  font-weight: var(--FontWeightHeavy);
}

.base-data-grid {
 flex-grow: 1;
}

.validationerror {
  color: red;
}


/*caseUploadWrapper*/

.popupContent {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.caseUploadWrapper {
  height: calc(100vh - 170px);
  min-height: calc(100vh - 180px);
  max-height: calc(100vh - 160px);
  min-width: 1100px;
  position: relative;
  display: flex;
  flex-direction: column;
  left: 0;
}

.caseUploadWrapper table.base-data-grid {
  margin-bottom: 0;
}

.caseUploadWrapper .child-table {
  min-height: calc(100vh - 550px);
}
.caseUploadWrapper .child-table table.base-data-grid {
  width: 100%;
}
.caseUploadWrapper .base-data-grid > tbody > tr.selected{
  background-color: #CFEBD0;
  color: #000;
}

.caseUploadWrapper .base-data-grid > tbody > tr.selected + tr .child-table thead tr {
  background-color: #cdcdcd;
}
.accordionArrow {
  display: flex;
  align-self: center;
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  transition: transform 0.25s;
}
.base-data-grid > tbody > tr.selected .accordionArrow {
  transform: rotate(90deg);
  transition: transform 0.25s;
}
.unusedColumnChild {
  font-weight: 500;
  color: #454545;
  background: repeating-linear-gradient(
    -45deg,
    #cacaca,
    #cacaca 4px,
    #bababa 4px,
    #bababa 8px
  );
}

.proceduresGrid h2 {
  text-align: left;
  margin: 0.25rem 0.5rem;
  font-weight: 500;
}

/*FOOTER*/
.caseUploadWrapper + .caseUploadFooter {
  align-self: center;
  display: flex;
  position: fixed;
  width: 50%;
  bottom: -1px;
  background: #fff;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem 0.5rem 0 0;
  box-shadow: 0 -3px 5px rgba(0, 0, 0, 0.2);
 }
 .caseUploadWrapper button {
  padding: 1rem;
 }
 .caseUploadFooter button {
  flex: 1;
  width: 18rem;
  padding: 0.5rem;
 }
 

/* UPLOADING ANIMA*/

.animeCell {
  display: flex;
  flex-direction: column;
  min-width: 12rem;
  min-height: 6rem;
  padding: 1rem;
  justify-content: center;
  font-size: 1.25rem;
}
.completingAnimation {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #00C681;
}
.animeCell label {
  margin-top: 0.5rem;
} 


.loader {
  width: 48px;
  height: 48px;
  border: 3px solid #19504c;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  align-self: center;
  justify-self: center;
} 
.loader::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-bottom-color: #F56200;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 


.gg-check {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs,1));
  width: 88px;
  height: 88px;
  border: 8px solid transparent;
  border-radius: 400px
 }
 
 .gg-check::after {
  content: "";
  display: block;
  color: #19504c;
  box-sizing: border-box;
  position: absolute;
  left: 12px;
  top: -4px;
  width: 24px;
  height: 40px;
  border-width: 0 8px 8px 0;
  border-style: solid;
  transform-origin: bottom left;
  transform: rotate(45deg)
 } 

 .completingAnimation .gg-check {
  width: 66px;
  height: 48px;
  border: 6px solid transparent;
  border-radius: 300px
 }
 .completingAnimation .gg-check::after {
  width: 16px;
  height: 24px;
  border-width: 0 6px 6px 0;
 }

 .thumbnailButton {
  display: inline-block;
  padding: 8px 16px;
  margin: 8px 15px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  background-color: #808080; /* Gray background */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  max-height: 3rem; /* Added max-height */
  overflow: hidden; /* Prevent content from spilling out */
  line-height: 1.2; /* Adjust line height for better text positioning */
}

.thumbnailButton:hover {
  background-color: #FFA500; /* Orange background on hover */
}

.aiFlowAnalysisSquare{
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 4px;
  color: '#00000';
  padding: 8px;
  background-color: #DA7E1F;
  border-radius: 4px;
}
.aiFlowEvalCellEm{
  padding: 4px;
  border-radius: 2px;
  font-size: 0.8em;
  text-align: center;
  background-color: '#fffff';
  color: '#00000';
}
.aiFlowEvalCell{
  padding: 8px;
  border-radius: 4px;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}
.tableStyle{
  border-collapse: separate;
  border-spacing: 0px 8px;
  width: 100%;
}
.cellStyle{
  padding: '8px';
  text-align: 'left';
}
.embeddingHeader{
  margin-bottom: '8px';
  color:'#00000';
}

.embeddingHolder{
  margin-top: '16px';
  color:'#00000';
}

.zoom-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.intui-button {
  width: 3rem;
  height: 2.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--swan);
  border-radius: 0.5rem;
  border: 1px solid var(--dolphin);
  cursor: pointer;
  color: var(--shark);
  transition: background-color 0.3s;
  font-size: 2.5rem;
  font-weight: normal; 
}

.intui-button:hover {
  background-color: #d1d5db;
}

.zoom-button:hover {
  background-color: #d1d5db;
}

.zoom-level {
  margin-top: 0.5rem;
}

.zoom-reset {
  font-size: 2.5rem;
}
.move-up,.move-down {
  font-weight: bold;
}

.TTFMScopeSettings{
  display: flex;
  align-items: center;

  gap: 0.5rem;

}

/*SCREEN size*/

@media (max-width: 1440px) {

  .caseUploadForm .innerBox,
  .caseUploadForm.uploadBox label{
    font-size: 14px;
    line-height: 1;
    font-weight: 500;
  }

  .caseUploadForm .innerBox.uploadCompleted {
    font-size: 16px;
  }

  

}
@media (max-width: 1138px) {

  .superHeader {
    max-width: 1138px;
    width: 1138px;
  }

}

.grid-sub-option {
  position: fixed;
  right: 0;
  bottom: 0;
  font-size: 1rem;
  padding: 0.7rem 1rem 0.6rem 2rem;
  background: var(--thunderfox);
  font-weight: bold;
  border-radius: 0.5rem 0 0 0rem;
  box-shadow: -3px 0 3px rgba(0, 0, 0, .10);
  border-left: 2px solid #999;
}


.version-label
{
  position: fixed;
  right: 0px;
  bottom: 0px;
  font-style: italic;
  color: grey;
}

.video-js.full-imaging
{
  width: 100%;
  height: 100%;
}